import clsx from 'clsx';

import Typography from '@ui/Typography';
import Image from '@ui/Image';
import ItemsTicker from '@ui/ItemsTickerNew';
import Button from '@ui/Button';
import MediaQuery from '@ui/MediaQuery';
import StaticIcon from '@ui/StaticIcon';
import { t } from '@lib/i18n';

import './LeadMagnitBanner.scss';

const colorsVariants = {
  teal: 'border-teal300 bg-teal100/30',
  orange: 'border-orange300 bg-orange100/30',
};

const imageBg = {
  teal: 'bg-teal100',
  orange: 'bg-orange100',
};

const tickerBg = {
  teal: 'bg-teal500',
  orange: 'bg-orange500',
};

const BannerTicker = ({ data, color }) => {
  const items = [data.text];
  
  const itemComponents = items.map((item, index) => {
    return (
      <div className="flex" key={index}>
        <Typography variant="body2" uppercase color="white" className="mx-[2px]">
          {item}
        </Typography>
        <StaticIcon name={data.icon} />
      </div>
    );
  });

  return (
    <div>
      <ItemsTicker
        tickerProps={{ className: clsx('h-[30px] md-up:rounded-br-[10px]', tickerBg[color]), speed: 50 }}
        items={itemComponents}
      />
    </div>
  );
};

const LeadMagnitBanner = () => {
  const {
    color, title, subtitle, button_name, button_link, image, ticker, alt_image,
  } = t('blog.lead_magnit_banner.webinar');

  const onButtonClick = () => {
    window.open(button_link, '_blank');
  };
  
  return (
    <div
      className={clsx(
        'md-up:grid-layout mx-auto mb-6 overflow-x-hidden rounded-2xl border-2 border-solid sm:mx-3 sm:px-0 md:p-2 lg:p-3',
        'lead-magnit-banner__wrap',
        colorsVariants[color], 
      )}
    >
      <div className="flex rounded-[10px] sm:flex-col-reverse">
        <div className="flex flex-col items-start justify-center bg-white/[.82] sm:rounded-b-xl sm:px-3.5 sm:pt-3.5 sm:pb-5 md:px-4.5 md-up:rounded-l-[10px] lg:px-7">
          <Typography variant="header3" className="md:text-xl">
            {title}
          </Typography>
          <MediaQuery greaterThan="md">
            <Typography className="mt-2 text-secondary200">
              {subtitle}
            </Typography>
          </MediaQuery>
          <Button className="mt-3.5 lg-down:w-full" onClick={onButtonClick}>
            {button_name}
          </Button>
        </div>
        <a 
          href={button_link}
          target="_blank"
          className={clsx(
            'flex max-w-[472px] flex-col justify-end sm:rounded-t-2xl md:w-[320px] md-up:rounded-r-[10px]',
            imageBg[color],
          )}
        >
          <Image src={`/images/blog/${image}`} alt={alt_image} className="w-[inherit] self-center rounded-tr-[10px] sm:rounded-tl-[10px]" />
          <BannerTicker data={ticker} color={color} />
        </a>
      </div>
    </div>
  );
};

export default LeadMagnitBanner;
