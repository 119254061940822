import {
  PostCard,
  PostsByCategory,
  RecommendedPosts,
  useSitePosts,
} from '@entities/post';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import { Layout } from '@widgets/layout';
import { LeadMagnitBanner } from './ui/LeadMagnitBanner';
import { VideoSection } from './ui/VideoSection';

export const BlogV2 = () => {
  const groupedPosts = useSitePosts();
  console.log(groupedPosts);
  return (
    <Layout seo={t('seo.blog')}>
      <Layout.Intro>
        <div className="grid-layout mx-auto pb-5 lg:pt-[96px] lg-down:pb-[53px] lg-down:pt-[56px]">
          <Typography variant="header1" className="text-center lg:pt-11 lg-down:pt-7">
            {t('blog.header')}
          </Typography>
          <Typography variant="subhead1" className="mt-4 text-center">
            {t('blog.subheader')}
          </Typography>
          <PostCard
            post={groupedPosts.general !== undefined ? groupedPosts.general[0] : groupedPosts.All[0]}
            isGeneral
            className="lg:mt-9 lg-down:mt-5"
          />
        </div>
      </Layout.Intro>
      <div className="gradient_light relative -mt-2 sm:mx-1 md:mx-2 lg:mx-3">
        <RecommendedPosts
          className="lg:pt-12 lg:pb-9 lg-down:pt-7"
          listClassName="lg:mt-6 lg-down:mt-5"
          posts={groupedPosts.recommended}
        />
      </div>
      <div className="grid-layout mx-auto lg:my-9 lg-down:mt-7 lg-down:mb-6">
        <PostsByCategory posts={groupedPosts} />
      </div>
      <LeadMagnitBanner />
      <VideoSection />
    </Layout>
  );
};
